import { configureStore, createAction } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses localStorage
import { combineReducers } from "redux";
import exchangeIdReducer from "./exchangeIdSlice";
import partyIdReducer from "./partyIdSlice";
import poiIdReducer from "./poiIdSlice";
import reconciliationIdReducer from "./reconciliationIdSlice";
import responseReducer from "./responseSlice";
import saleReferenceIdentificationReducer from "./saleReferenceIdentificationSlice";
import saleTransactionIdentificationReducer from "./transactionReferenceSlice";
import chosenServerReducer from "./chosenServerSlice";
import merchantIdReducer from "./merchantIdSlice";
import reconciliationResponseReducer from "./reconciliationResponseSlice"
export const resetAll = createAction("resetAll");

// Persist config
const persistConfig = {
  key: "root",
  storage,
};

// Combine reducers (if you have more than one)
const rootReducer = combineReducers({
  exchangeId: exchangeIdReducer,
  partyId: partyIdReducer,
  poiId: poiIdReducer,
  reconciliationId: reconciliationIdReducer,
  response: responseReducer,
  saleReferenceIdentification: saleReferenceIdentificationReducer,
  transactionReference: saleTransactionIdentificationReducer,
  chosenServer: chosenServerReducer,
  merchantId: merchantIdReducer,
  reconciliationResponse: reconciliationResponseReducer
});

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
export const store = configureStore({
  reducer: persistedReducer,
});

// Persistor
export const persistor = persistStore(store);
