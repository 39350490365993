// ********************** Connection Details for the Authorization server **********************


// export const AuthServer_URL = "http://localhost:8110";
export const AUTHSERVER_URL = "https://auth-demo.fvpay.ca/api";

export function getFetchSecretURL(termID) {
	return AUTHSERVER_URL + "/onboard/" + termID;
}

export function getTOTPURL(termID, totp) {
	return AUTHSERVER_URL + "/onboardId/" + termID + "/totp/" + totp;
}

// ********************** Connection Details for the Dev Server **********************

export const DEV_URL = "https://corsdev.fvpay.ca";

// ********************** Connection Details for the London Server **********************
// You can tell which server your terminal is connected to in the settings page

// export const TSALE_URL = "http://" + London_IP + ":28014";
// This is a temporary URL for working around CORS issues when you are live you shouldnt have these issues

export const LONDON_URL = "https://corspg1.fvpay.ca";


// ********************** Connection Details for the Ottawa Server **********************
// You can tell which server your terminal is connected to in the settings page

// export const TSALE_URL = "http://" + OTTAWA_IP + ":28014";
// This is a temporary URL for working around CORS issues when you are live you shouldnt have these issues
export const OTTAWA_URL = "https://cors.fvpay.ca/";


// ********************** Connection Details for the UAT Server **********************

export const UAT_URL = "https://corsuat.fvpay.ca";

export function getSalePaymentURL(URL){
    return URL + "/SalePmtReq";
};

export function getSaleReconciliationURL(URL){
    return URL + "/SaleRcncltReq";
};