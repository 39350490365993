// redux/poiIdSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./store";


const initialState = {
	value: "FNV00005",
};

const poiIdSlice = createSlice({
	name: "poiId",
	initialState,
	reducers: {
		setPOIId: (state, action) => {
			state.value = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => ({ value: initialState.value }));
	},
});

export const { setPOIId } = poiIdSlice.actions;
export default poiIdSlice.reducer;
