import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./store";


const initialState = {
	value: "FNVM00000000002",
};

const merchantIdSlice = createSlice({
	name: "merchantId",
	initialState,
	reducers: {
		setMerchantId: (state, action) => {
            state.value = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => ({ value: initialState.value }));
	},
});

export const { setMerchantId } = merchantIdSlice.actions;
export default merchantIdSlice.reducer;
