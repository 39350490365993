import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./store";


const initialState = {
	value: new Date().toISOString().split("T")[0].replaceAll("-", ""),
};

const reconciliationIdSlice = createSlice({
	name: "reconciliationId",
	initialState,
	reducers: {
		setReconciliationId: (state, action) => {
			if (!action.payload < 1) {
				state.value = action.payload;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => ({ value: initialState.value }));
	},
});

export const { setReconciliationId } = reconciliationIdSlice.actions;
export default reconciliationIdSlice.reducer;
