import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../css/App.css";
import { postSender } from "../json/RequestSender.js";
import { SaleReconciliationRequest } from "../json/SaleReconciliationRequest.js";
import { DEV_URL, getSaleReconciliationURL, LONDON_URL, OTTAWA_URL, UAT_URL } from "../json/ServerInfo.js";
import { setChosenServer } from "../redux/chosenServerSlice.js";
import { setMerchantId } from "../redux/merchantIdSlice.js";
import { setReconciliationId } from "../redux/reconciliationIdSlice.js";
import { setReconciliationResponse } from "../redux/reconciliationResponseSlice.js";
import { setTransactionReference } from "../redux/transactionReferenceSlice.js";

let reconRequest = SaleReconciliationRequest;

const SaleReconiliationForm = () => {
	const getServerName = (url) => {
		switch (url) {
			case UAT_URL:
				return "UAT Server";
			case DEV_URL:
				return "DEV Server";
			case OTTAWA_URL:
				return "Ottawa Server";
			case LONDON_URL:
				return "London Server";
			default:
				return "";
		}
	};

	const dispatch = useDispatch();

	const reconId = useSelector((state) => state.reconciliationId.value);
	const reconciliationResponse = useSelector((state) => state.reconciliationResponse.value);
	const merchantId = useSelector((state) => state.merchantId.value);
	const chosenServer = useSelector((state) => state.chosenServer.value);
	const txReference = useSelector((state) => state.transactionReference.value);
	const [serverName, setServerName] = useState(getServerName(chosenServer));

	const handleFetchData = async () => {
		dispatch(setReconciliationId(document.getElementById("reconciliationId").value));

		console.log("Form Submitted");
		reconRequest.RcncltReq.Tx.SaleTxId.TxDt = new Date().toISOString();
		reconRequest.RcncltReq.Tx.SaleTxId.TxRef = txReference;
		dispatch(setTransactionReference(Number(txReference) + 1));
		reconRequest.RcncltReq.Tx.SaleRcncltId = reconId;

		// ****************************
		// Important to set the date before sending
		reconRequest.Hdr.CreDtTm = new Date().toISOString();
		// ****************************

		let data = "";
		try {
			console.log("Sent to: " + chosenServer);
			data = await postSender(reconRequest, getSaleReconciliationURL(chosenServer));

			if (data.error != null) {
				console.log("Output Error  : ", data.error);
				dispatch(setReconciliationResponse(data.error));
			} else {
				console.log("Output : ", data);
				dispatch(setReconciliationResponse(JSON.stringify(data)));
			}
		} catch (error) {
			if (error != null) {
				console.log("Catch Error", error);
				dispatch(setReconciliationResponse(error));
			} else {
				console.log("Catch Error", "NULL");
			}
		}
	};

	const resetValues = async () => {
		console.log(new Date());
	};

	const updateServer = (url) => {
		setServerName(getServerName(url));
		dispatch(setChosenServer(url));
	};


	return (
		<div className="container mt-4">
			<div className="contentContainer">
				<div className="titleWrappers">
					<h1>Enter Fields for TSALE Reconciliation Request</h1>
				</div>
				<div className="inputWrapper">
					<div className="form-group">
						<div>
							<label htmlFor="reconciliationId">Reconcilation Id</label>
							<input type="text" id="reconciliationId" className="form-control mb-2" value={reconId} />
						</div>
						<div>
							<label htmlFor="merchantID">Merchant Id</label>
							<div className="dropdown">
								<button id="merchantID" className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
									{merchantId}
								</button>
								<ul className="dropdown-menu">
									<li>
										<button className="dropdown-item" type="button" onClick={() => dispatch(setMerchantId("FNVM00000000001"))}>
											FNVM00000000001
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => dispatch(setMerchantId("FNVM00000000002"))}>
											FNVM00000000002
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<label htmlFor="chosenServer">Chosen Server</label>
							<div className="dropdown">
								<button id="chosenServer" className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
									{serverName}
								</button>
								<ul className="dropdown-menu">
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateServer(UAT_URL)}>
											UAT Server
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateServer(DEV_URL)}>
											DEV Server
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateServer(OTTAWA_URL)}>
											Ottawa Server
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateServer(LONDON_URL)}>
											London Server
										</button>
									</li>
								</ul>
							</div>
						</div>
						<button className="btn btn-secondary" onClick={resetValues}>
							Reset Values
						</button>
					</div>
				</div>
			</div>

			<div className="footer mt-4">
				<p>Response: {reconciliationResponse}</p>
				<button className="btn btn-primary" onClick={handleFetchData}>
					Send request
				</button>
			</div>
		</div>
	);
};

export default SaleReconiliationForm;
