// redux/partyIdSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./store";


const initialState = {
	value: "PAX-A920-0823334455",
};

const partyIdSlice = createSlice({
	name: "partyId",
	initialState,
	reducers: {
		setPartyId: (state, action) => {
			state.value = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => ({ value: initialState.value }));
	},
});

export const { setPartyId } = partyIdSlice.actions;
export default partyIdSlice.reducer;
