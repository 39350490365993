import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./store";

const initialState = {
	value: "1",
};

const transactionReference = createSlice({
	name: "transactionReference",
	initialState,
	reducers: {
		setTransactionReference: (state, action) => {
            if (!action.payload < 1){
                state.value = action.payload;
            }
		},
	},
    extraReducers: (builder) => {
		builder.addCase(resetAll, () => ({ value: initialState.value }));
	},
});

export const { setTransactionReference } = transactionReference.actions;
export default transactionReference.reducer;
