import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import Layout from "./pages/Layout";
import "./index.css";

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Layout />
            </PersistGate>
        </Provider>
    );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
