import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./store";


const initialState = {
	value: "1",
};

const saleReferenceIdentificaton = createSlice({
	name: "saleReferenceIdentificaton",
	initialState,
	reducers: {
		setSaleReferenceIdentificaton: (state, action) => {
            if (!action.payload < 1){
                state.value = action.payload;
            }
		},
	},
    extraReducers: (builder) => {
		builder.addCase(resetAll, () => ({ value: initialState.value }));
	},
});

export const { setSaleReferenceIdentificaton } = saleReferenceIdentificaton.actions;
export default saleReferenceIdentificaton.reducer;
