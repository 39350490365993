// redux/poiIdSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./store";


const initialState = {
	value: "No Reponse stored",
};

const reconciliationResponse = createSlice({
	name: "reconciliationResponse",
	initialState,
	reducers: {
		setReconciliationResponse: (state, action) => {
			state.value = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => ({ value: initialState.value }));
	},
});

export const { setReconciliationResponse } = reconciliationResponse.actions;
export default reconciliationResponse.reducer;
