import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../css/App.css";
import { postSender } from "../json/RequestSender.js";
import { SalePaymentRequest } from "../json/SalePaymentRequest.js";
import { DEV_URL, getSalePaymentURL, LONDON_URL, OTTAWA_URL, UAT_URL } from "../json/ServerInfo.js";
import { setChosenServer } from "../redux/chosenServerSlice.js";
import { setExchangeId } from "../redux/exchangeIdSlice";
import { setMerchantId } from "../redux/merchantIdSlice.js";
import { setPartyId } from "../redux/partyIdSlice";
import { setPOIId } from "../redux/poiIdSlice";
import { setReconciliationId } from "../redux/reconciliationIdSlice.js";
import { setResponse } from "../redux/responseSlice";
import { setSaleReferenceIdentificaton } from "../redux/saleReferenceIdentificationSlice.js";
import { resetAll } from "../redux/store.js";
import { setTransactionReference } from "../redux/transactionReferenceSlice.js";

let paymentRequest = SalePaymentRequest;

const SalePaymentForm = () => {
	const getServerName = (url) => {
		switch (url) {
			case UAT_URL:
				return "UAT Server";
			case DEV_URL:
				return "DEV Server";
			case OTTAWA_URL:
				return "Ottawa Server";
			case LONDON_URL:
				return "London Server";
			default:
				return "";
		}
	};

	// Holds Total Amount
	const initTaxAmt = SalePaymentRequest.PmtReq.Tx.TxDtls.DtldAmt.Tax[0].TaxAmt;
	const initAmt = SalePaymentRequest.PmtReq.Tx.TxDtls.DtldAmt.AmtGoodsAndSvcs;

	const [amt, setAmt] = useState(initAmt);
	const [taxAmt, setTaxAmt] = useState(initTaxAmt);
	// // used to switch between london and ottawa server
	const [transactionType, setTransactionType] = useState("CRDP");
	const [transactionTypeDisplay, setTransactionTypeDisplay] = useState("Card Payment");

	const dispatch = useDispatch();

	const chosenServer = useSelector((state) => state.chosenServer.value);
	const exchangeId = useSelector((state) => state.exchangeId.value);
	const partyId = useSelector((state) => state.partyId.value);
	const poiId = useSelector((state) => state.poiId.value);
	const reconciliationId = useSelector((state) => state.reconciliationId.value);
	const response = useSelector((state) => state.response.value);
	const saleReferenceIdentification = useSelector((state) => state.saleReferenceIdentification.value);
	const transactionReference = useSelector((state) => state.transactionReference.value);
	const merchantId = useSelector((state) => state.merchantId.value);
	const [serverName, setServerName] = useState(getServerName(chosenServer));

	const handleFetchData = async () => {
		setAmt(document.getElementById("amt").value);
		setTaxAmt(document.getElementById("taxAmt").value);
		dispatch(setPartyId(document.getElementById("ptyID").value));
		dispatch(setPOIId(document.getElementById("POIID").value));
		dispatch(setSaleReferenceIdentificaton(document.getElementById("saleReferenceID").value));
		dispatch(setTransactionReference(document.getElementById("saleTransactionIdentificationReference").value));
		dispatch(setReconciliationId(document.getElementById("saleReconcilationID").value));

		// console.log("Validating fields");
		// if (!validateFields()) {
		// 	return;
		// }

		console.log("Form Submitted");

		paymentRequest.Hdr.XchgId = exchangeId;
		dispatch(setExchangeId(Number(exchangeId) + 1));

		paymentRequest.Hdr.InitgPty.PtyId = partyId;

		paymentRequest.PmtReq.Tx.TxDtls.TtlAmt = (parseFloat(amt) + parseFloat(taxAmt)).toFixed(2) + "";
		paymentRequest.PmtReq.Tx.TxDtls.DtldAmt.AmtGoodsAndSvcs = parseFloat(amt).toFixed(2) + "";
		paymentRequest.PmtReq.Tx.TxDtls.DtldAmt.Tax[0].TaxAmt = parseFloat(taxAmt).toFixed(2) + "";

		paymentRequest.PmtReq.Envt.POI.POIId = poiId;
		paymentRequest.PmtReq.Tx.SaleRefId = saleReferenceIdentification;
		dispatch(setSaleReferenceIdentificaton(Number(saleReferenceIdentification) + 1));

		paymentRequest.PmtReq.Tx.SaleTxId.TxRef = transactionReference;
		dispatch(setTransactionReference(Number(transactionReference) + 1));

		paymentRequest.PmtReq.Tx.SaleRcncltId = reconciliationId;
		paymentRequest.PmtReq.Tx.TxTp = transactionType;

		paymentRequest.PmtReq.Envt.Mrchnt.MrchntId = merchantId;

		// ****************************
		// Important to set the date before sending
		paymentRequest.Hdr.CreDtTm = new Date().toISOString();
		paymentRequest.PmtReq.Tx.SaleTxId.TxDt = new Date().toISOString();
		// ****************************

		console.log(paymentRequest);

		let data = "";
		try {
			console.log("Sent to: " + chosenServer);
			data = await postSender(paymentRequest, getSalePaymentURL(chosenServer));

			if (data.error != null) {
				console.log("Output Error  : ", data.error);
				dispatch(setResponse(data.error));
			} else {
				console.log("Output : ", data);
				dispatch(setResponse(JSON.stringify(data)));
			}
		} catch (error) {
			if (error != null) {
				console.log("Catch Error", error);
				dispatch(setResponse(error));
			} else {
				console.log("Catch Error", "NULL");
			}
		}
	};

	const resetValues = () => {
		console.log(new Date());
		// This is the default PartyID. you can have whatever party ID youd like
		dispatch(resetAll());
	};

	const amountReEx = new RegExp("^([0-9]*[.]{0,1}[0-9]{0,2})$");

	// const validateFields = () => {
	// 	// Fetch all the forms we want to apply custom Bootstrap validation styles to
	// 	const forms = document.querySelectorAll(".form-control");
	// 	let success = true;
	// 	// Loop over them and prevent submission
	// 	Array.from(forms).forEach((form) => {
	// 		switch (form.id) {
	// 			case "saleReferenceID":
	// 			case "saleTransactionIdentificationReference":
	// 			case "ptyID":
	// 				if (form.value.length < 1 || form.value.length > 35) {
	// 					form.className = "form-control mb-2 is-invalid";
	// 					success = false;
	// 				} else {
	// 					form.className = "form-control mb-2";
	// 				}
	// 				break;
	// 			case "ttlAmt":
	// 				if (!amountReEx.test(form.value)) {
	// 					form.className = "form-control mb-2 is-invalid";
	// 					success = false;
	// 				} else {
	// 					form.className = "form-control mb-2";
	// 				}
	// 				break;
	// 			case "POIID":
	// 				if (form.value.length < 1 || form.value.length > 8) {
	// 					form.className = "form-control mb-2 is-invalid";
	// 					success = false;
	// 				} else {
	// 					form.className = "form-control mb-2";
	// 				}
	// 				break;
	// 			case "saleReconcilationID":
	// 				if (form.value.length < 1 || form.value.length > 10) {
	// 					form.className = "form-control mb-2 is-invalid";
	// 					success = false;
	// 				} else {
	// 					form.className = "form-control mb-2";
	// 				}
	// 				break;
	// 			default:
	// 				success = false;
	// 		}
	// 	});
	// 	return success;
	// };

	const updateTransactionType = (txTp) => {
		switch (txTp) {
			case "Card Payment":
				setTransactionType("CRDP");
				break;
			case "Refund":
				setTransactionType("RFND");
				break;
			default:
				break;
		}

		setTransactionTypeDisplay(txTp);
	};

	const updateServer = (url) => {
		setServerName(getServerName(url));
		dispatch(setChosenServer(url));
	};

	return (
		<div className="container mt-4">
			<div className="contentContainer">
				<div className="titleWrappers">
					<h1>Enter Fields for TSALE Sale Payment Request</h1>
				</div>
				<div className="inputWrapper">
					<div className="form-group">
						<div>
							<label htmlFor="ptyID">Transaction Type</label>
							<div className="dropdown">
								<button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
									{transactionTypeDisplay}
								</button>
								<ul className="dropdown-menu">
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateTransactionType("Card Payment")}>
											Card Payment
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateTransactionType("Refund")}>
											Refund
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<label htmlFor="ptyID">Party ID</label>
							<input type="text" id="ptyID" className="form-control mb-2" value={partyId} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
						<div>
							<label htmlFor="merchantId">Merchant Id</label>
							<div className="dropdown">
								<button id="merchantId" className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
									{merchantId}
								</button>
								<ul className="dropdown-menu">
									<li>
										<button className="dropdown-item" type="button" onClick={() => dispatch(setMerchantId("FNVM00000000001"))}>
											FNVM00000000001
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => dispatch(setMerchantId("FNVM00000000002"))}>
											FNVM00000000002
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<label htmlFor="amt">Amount and Tax (will be added together in request)</label>
						</div>
						<div className="input-group">
							<input type="text" id="amt" className="form-control" value={amt} />
							<input type="text" id="taxAmt" className="form-control" value={taxAmt} />
							<div className="invalid-feedback">Must be in format of 0.00</div>
						</div>
						<div>
							<label htmlFor="POIID">POI ID</label>
							<input type="text" id="POIID" className="form-control mb-2" value={poiId} />
							<div className="invalid-feedback">Must be between 1 and 8 characters</div>
						</div>
						<div>
							<label htmlFor="saleReferenceID">Sale Reference ID</label>
							<input type="text" id="saleReferenceID" className="form-control mb-2" value={saleReferenceIdentification} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
						<div>
							<label htmlFor="saleTransactionIdentificationReference">Sale Transaction Identification Reference (This must be different for each transaction in the Batch or per Sale Reconciliation ID)</label>
							<input type="text" id="saleTransactionIdentificationReference" className="form-control mb-2" value={transactionReference} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
						<div>
							<label htmlFor="saleReconcilationID">Sale Reconcilation ID</label>
							<input type="text" id="saleReconcilationID" className="form-control mb-2" value={reconciliationId} />
							<div className="invalid-feedback">Must be between 1 and 10 characters</div>
						</div>
						<div>
							<label htmlFor="chosenServer">Chosen Server</label>
							<div className="dropdown">
								<button id="chosenServer" className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
									{serverName}
								</button>
								<ul className="dropdown-menu">
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateServer(UAT_URL)}>
											UAT Server
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateServer(DEV_URL)}>
											DEV Server
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateServer(OTTAWA_URL)}>
											Ottawa Server
										</button>
									</li>
									<li>
										<button className="dropdown-item" type="button" onClick={() => updateServer(LONDON_URL)}>
											London Server
										</button>
									</li>
								</ul>
							</div>
						</div>
						<button className="btn btn-secondary" onClick={resetValues}>
							Reset Values
						</button>
					</div>
				</div>
			</div>

			<div className="footer mt-4">
				<p>Response: {response}</p>
				<button className="btn btn-primary" onClick={handleFetchData}>
					Send request
				</button>
			</div>
		</div>
	);
};

export default SalePaymentForm;
