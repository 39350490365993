import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./store";


const initialState = {
	value: 1,
};

const exchangeIdSlice = createSlice({
	name: "exchangeId",
	initialState,
	reducers: {
		setExchangeId: (state, action) => {
			if (!action.payload < 1) {
				state.value = action.payload;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => ({ value: initialState.value }));
	},
});

export const { setExchangeId } = exchangeIdSlice.actions;
export default exchangeIdSlice.reducer;
