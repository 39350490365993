// redux/poiIdSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./store";


const initialState = {
	value: "No Reponse stored",
};

const responseSlice = createSlice({
	name: "response",
	initialState,
	reducers: {
		setResponse: (state, action) => {
			state.value = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => ({ value: initialState.value }));
	},
});

export const { setResponse } = responseSlice.actions;
export default responseSlice.reducer;
