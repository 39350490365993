import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./store";


const initialState = {
	value: "https://corsdev.fvpay.ca",
};

const chosenServerSlice = createSlice({
	name: "chosenServer",
	initialState,
	reducers: {
		setChosenServer: (state, action) => {
            state.value = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => ({ value: initialState.value }));
	},
});

export const { setChosenServer } = chosenServerSlice.actions;
export default chosenServerSlice.reducer;
